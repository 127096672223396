import React, {useState} from 'react';
import {Avatar, Menu, MenuItem} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';

import {userSignOut} from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import { Link } from 'react-router-dom';

const UserInfo = () => {

  const dispatch = useDispatch();

  const { profile } = useSelector(({ user }) => user);


  const [anchorE1, setAnchorE1] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setOpen(true);
    setAnchorE1(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  return (
    <div className="user-profile d-flex flex-row align-items-center">
      <Avatar
        alt="..."
        src={profile.avatar.url}
        className="user-avatar"
      />
      <div className="user-detail">
        <h4 className="user-name d-flex" onClick={handleClick}>
          <span className="text-truncate">{profile.name}</span>
          <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
        </h4>
      </div>
      <Menu
        className="user-info"
        id="simple-menu"
        anchorEl={anchorE1}
        open={open}
        onClose={handleRequestClose}
        PaperProps={{
          style: {
            minWidth: 120,
            paddingTop: 0,
            paddingBottom: 0
          }
        }}
      >
        <MenuItem
          component={Link}
          to="/app/profile"
          onClick={handleRequestClose}
        >
          <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
          <IntlMessages id="popup.profile" />
        </MenuItem>
        <MenuItem onClick={() => {
          handleRequestClose();
          dispatch(userSignOut());
        }}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

          <IntlMessages id="popup.logout" />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserInfo;
