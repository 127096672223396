import React, {useMemo} from 'react';

import {Button} from '@mui/material';
import 'reactjs-crontab/dist/index.css';
import api from '../../../services/api';

const NewClient = () => {

  const sendApi = () => {

    async function send() {
      const templateID = 'd2c41821-6027-4997-abcc-f58fd92891dd';
      const data = {
        source: '558007778585',
        destination: '5511985007350',
        template: {
          id: templateID,
          params: ['Joao Paulo', 'HOME FIBER 10MB', '15', '99,90', '15/08/2021', '69854',
            'RUA CEL JOAO LEME 460 - CENTRO - BRAGANÇA PAULISTA', 'teste@redenilf.com.br', '9.88888558777']
        }
      };

      const sendTemplate = await api.post('/templates_whatsApp', data);

      return console.log(sendTemplate.data);

    }

    send();

  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <Button
          className="float-right"
          onClick={() => sendApi()}
          variant="contained"
          >
          Enviar
        </Button>

      </div>
    </div>
  );
};

export default NewClient;
