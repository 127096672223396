import React, {useEffect, useState} from 'react';

import MUIDataTable from 'mui-datatables';
import {IconButton} from '@mui/material';

import {FaPen, FaTrash} from 'react-icons/all';
import FormEditUser from '../form/FormEditUser';
import FormDeletUser from '../form/FormDeletUser';

const options = {
  selectableRows: 'none',
  sortOrder: {
    name: 'id',
    direction: 'desc'
  }
};

export default function ({data, dataGroup}) {

  const [openEdit, setOpenEdit] = useState(false);
  const [openDel, setOpenDel] = useState(false);

  const [dataTable, setDataTable] = useState([]);

  const [selectedUser, setUser] = useState({});

  const handleEdit = (value) => {

    setUser(value);
    setOpenEdit(true);
  };
  const handleDelete = (value) => {

    setUser(value);
    setOpenDel(true);
  };

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'name',
      label: 'Nome',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'group',
      label: 'Grupo',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'created_at',
      label: 'Criado em',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'updated_at',
      label: 'Atualizado em',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'action',
      label: 'Acão',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            <IconButton onClick={() => handleEdit(...data.filter((item) => item.id === tableMeta.rowData[0]))}>
              <FaPen size={15} />
            </IconButton>
            <IconButton onClick={() => handleDelete(...data.filter((item) => item.id === tableMeta.rowData[0]))}>
              <FaTrash size={15} />
            </IconButton>
          </>
        )
      }
    }
  ];

  const handleDialogEdit = (value) => setOpenEdit(value);
  const handleDialogDel = (value) => setOpenDel(value);

  useEffect(() => {
    const usersTable = data.map((row) => ({
      id: row.id,
      name: row.name,
      email: row.email,
      group: row.group.name,
      created_at: new Intl.DateTimeFormat('pt-br').format(new Date(row.created_at)),
      updated_at: new Intl.DateTimeFormat('pt-br').format(new Date(row.updated_at)),
    }));
    setDataTable(usersTable);

  }, [data]);

  return (
    <>
      <MUIDataTable columns={columns} data={dataTable} options={options} />
      <FormEditUser
        dataUser={selectedUser}
        dataTable={dataTable}
        dataGroup={dataGroup}
        setDataTable={setDataTable}
        open={openEdit}
        dialog={handleDialogEdit} />
      <FormDeletUser
        dataUser={selectedUser}
        dataTable={dataTable}
        setDataTable={setDataTable}
        open={openDel}
        dialog={handleDialogDel} />
    </>
  );
}
