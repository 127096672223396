import {
  all, call, fork, put, takeEvery
} from 'redux-saga/effects';

import { SIGNIN_USER, SIGNOUT_USER } from '../constants/ActionTypes';

import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
} from '../actions/Auth';

import api from '../services/api';

import errorApi from '../util/errorApi';

export function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const response = yield call(api.post, '/session', { email, password });

    const { token, user } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    localStorage.setItem('user_id', token);
    localStorage.setItem('profile', JSON.stringify(user));
    yield put(userSignInSuccess({ token, user }));
  } catch (error) {
    yield put(showAuthMessage(errorApi(error)));
  }
}

function* signOut() {
  localStorage.removeItem('user_id');
  localStorage.removeItem('profile');
  yield put(userSignOutSuccess(signOutUser));
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser), fork(signOutUser)]);
}
