import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import {
  Button, DialogContent, DialogTitle, MenuItem, TextField
} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {FaPlus} from 'react-icons/all';
import {NotificationManager} from 'react-notifications';
import api from '../../../../services/api';
import errorApi from '../../../../util/errorApi';

export default ({
  dataUser, setData, dataGroup
}) => {
  const [open, setOpen] = useState(false);

  const defaultValues = {
    name: '',
    email: '',
    group_id: '',
    password: ''
  };
  const {
    handleSubmit, control, reset
  } = useForm({
    defaultValues
  });

  const onSubmit = async (data) => {

    try {
      const newUser = await api.post('/users', data);
      setData([...dataUser, newUser.data]);
      setOpen(false);
      reset();

    } catch (e) {
      console.log(e);
      NotificationManager.warning(errorApi(e));
    }

  };

  return (
    <>
      <Button
        className="float-right"
        onClick={() => setOpen(true)}
        variant="contained"
        color="primary">
        Cadastrar
      </Button>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        aria-labelledby="max-width-dialog-title"
        onClose={() => setOpen(false)}
      >
        <DialogTitle id="max-width-dialog-title">
          Novo Usuário
          <hr />
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-12">
                <Controller
                  name="name"
                  control={control}
                  render={({
                    field: {
                      onChange, value
                    }, fieldState: {error}
                  }) => (
                    <TextField
                      label="Nome e sobrenome"
                      autoComplete="off"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      error={!!error}
                      onChange={onChange}
                      value={value}
                      helperText={error ? error.message : null}
                    />
                  )}
                  rules={{required: 'Nome é Obrigatório'}}
                />
              </div>
              <div className="col-12">
                <Controller
                  name="email"
                  control={control}
                  render={({
                    field: {
                      onChange, value
                    }, fieldState: {error}
                  }) => (
                    <TextField
                      label="Email"
                      autoComplete="off"
                      margin="normal"
                      type="email"
                      variant="outlined"
                      fullWidth
                      error={!!error}
                      onChange={onChange}
                      value={value}
                      helperText={error ? error.message : null}
                    />
                  )}
                  rules={{required: 'Email é Obrigatório'}}
                />
              </div>
              <div className="col-12">
                <Controller
                  name="password"
                  control={control}
                  render={({
                    field: {
                      onChange, value
                    }, fieldState: {error}
                  }) => (
                    <TextField
                      label="Senha mínimo 6 caracteres"
                      autoComplete="off"
                      margin="normal"
                      variant="outlined"
                      type="password"
                      fullWidth
                      error={!!error}
                      onChange={onChange}
                      value={value}
                      helperText={error ? error.message : null}
                    />
                  )}
                  rules={{required: 'Password é Obrigatório', minLength: 6}}
                />
              </div>
              <div className="col-12">
                <Controller
                  name="group_id"
                  control={control}
                  render={({
                    field: {
                      onChange, value
                    }, fieldState: {error}
                  }) => (
                    <TextField
                      label="Grupo"
                      margin="normal"
                      variant="outlined"
                      select
                      fullWidth
                      error={!!error}
                      onChange={onChange}
                      value={value}
                      helperText={error ? error.message : null}
                    >
                      {
                        dataGroup.map((group) => (
                          <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                        ))
                      }
                    </TextField>
                  )}
                  rules={{required: 'Grupo é Obrigatório', minLength: 6}}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Button
                  variant="contained"
                  color="primary"
                  className="float-right my-4"
                  type="submit"
                  size="medium"
                  endIcon={<FaPlus size={13} />}
                >
                  Inserir
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
