import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import {ToastContainer} from "react-toastify";
import {NotificationContainer} from "react-notifications";


const AfterSales = ({match}) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/audit`}/>
        <Route path={`${match.url}/audit`} component={asyncComponent(() => import('./routes/Audit'))}/>
        <Route component={asyncComponent(() => import('../extraPages/routes/404'))}/>
      </Switch>
      <ToastContainer />
      <NotificationContainer />
    </div>
  );
}

export default AfterSales;
