import React, {useEffect, useState} from 'react';
import {
  Tabs, Tab
} from '@mui/material';

import {NotificationContainer, NotificationManager} from 'react-notifications';
import {useDispatch} from 'react-redux';
import Auxiliary from '../../../../util/Auxiliary';
import UserTable from '../table/UserTable';
import api from '../../../../services/api';
import {userSignOut} from '../../../../actions/Auth';
import errorApi from '../../../../util/errorApi';
import FormNewUser from '../form/FormNewUser';

export default () => {
  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);

  const [value, setValue] = useState(0);

  async function loadUserData() {
    try {
      const loadUsers = await api.get('/admin/users');
      setUsers(loadUsers.data);
    } catch (e) {

      console.log(e);
      dispatch(userSignOut());
      NotificationManager.warning(errorApi(e));
    }
  }

  async function loadGroupData() {
    try {
      const loadUsers = await api.get('/admin/groups');
      setGroups(loadUsers.data);
    } catch (e) {

      console.log(e);
      dispatch(userSignOut());
      NotificationManager.warning(errorApi(e));
    }
  }

  useEffect(() => {

    loadUserData();
    loadGroupData();
  }, []);

  const handleChange = (event, value) => {
    setValue(value);
  };

  return (
    <div className="jr-tabs-classic">
      <FormNewUser
        dataUser={users}
        dataGroup={groups}
        setData={setUsers} />
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{style: {background: '#039be5'}}}
        textColor="primary"
        className="jr-tabs-up"
        centered>
        <Tab label="Usuários" />
        <Tab label="Grupos" />

      </Tabs>
      <div className="jr-tabs-content jr-task-list">
        <div className="row">
          {value === 0 && (
            <div className="col-12">
              <UserTable dataGroup={groups} data={users} setData={setUsers} />
            </div>
          )}
          {value === 1 && (
            <Auxiliary>
              Item Two
            </Auxiliary>
          )}
        </div>
      </div>
      <NotificationContainer />
    </div>
  );

};
