import React from 'react';
import {Route} from 'react-router-dom';
import ContainerHeader from '../../../components/ContainerHeader/index';
import CardBox from '../../../components/CardBox';

import CenteredTabs from './tabs/CenteredTabs';
import NewClient from '../../../components/WhatsApp/Templates/NewClient';
import AccessPage from '../../../security/AccessPage';
import asyncComponent from '../../../util/asyncComponent';

export default ({match}) => {
  const access = AccessPage(match);

  if(!access){
    return (
    <Route component={asyncComponent(() => import('../extraPages/routes/404'))}/>
    )}

  return (
    <div className="app-wrapper">
      <ContainerHeader match={match} title="Configurações" />
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <div className="row">
          <CardBox styleName="col-lg-12" heading="Sistema">
            <CenteredTabs />
          </CardBox>
          <CardBox styleName="col-lg-6" heading="API">
            <NewClient />
          </CardBox>
        </div>
      </div>
    </div>
  );
};
