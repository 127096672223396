import React, {useEffect, useState} from 'react';
import {
  Badge,
  Drawer, IconButton, Tab, Tabs
} from '@mui/material';
import {
  FaAlignRight,
  MdAssignmentInd,
  MdBadge, MdDescription,
} from 'react-icons/all';
import {makeStyles, useTheme} from '@mui/styles';
import {ChevronLeft} from '@mui/icons-material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SwipeableViews from 'react-swipeable-views';
import {NotificationManager, NotificationContainer} from 'react-notifications';
import CmtList from '../../@coremat/CmtList';
import NotificationCell from './components/NotificationCell';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  scrollbarRoot: {
    height: 800,
  },
}));

export const notifications = {
  osNotifications: [],
  contractNotifications: [],
  ticketNotifications: [],
};

/*  Modelo
export const notificationsTest = {
  osNotifications: [{
    id: 1,
    cliente: 'AYLTON MARTINS JUNIOR',
    codigo: 161485,
    descricao: 'Os Pendente',
    data: '29/04/2021',
    operador: 'Daiane Ramos Silva',
    tipo: 'Retirada',
  }, {
    id: 2,
    cliente: 'Jose MARTINS JUNIOR',
    codigo: 161485,
    descricao: 'Os Pendente',
    data: '29/04/2021',
    operador: 'Juliana Oliveira',
    tipo: 'Retirada',
  }, {
    id: 3,
    cliente: 'AYLTON MARTINS JUNIOR',
    codigo: 161485,
    descricao: 'Os Pendente',
    data: '29/04/2021',
    operador: 'Yasmin Silva',
    tipo: 'Retirada',
  }],
  contractNotifications: [],
  ticketNotifications: [],
};
*/


export default function NotificationsControllerMK() {
  const theme = useTheme();
  const classes = useStyles();

  const [drawerStatus, setDrawerStatus] = useState(false);
  const [valueTab, setValueTabs] = useState(0);
  const [alert, setAlert] = useState(false);

  const [notifications, setNotifications] = useState({
    osNotifications: [],
    contractNotifications: [],
    ticketNotifications: [],
  });

  async function loadOsNotification() {

    try {

      const response = await api.get('notifications_mk');

      if (response.data) {
        setNotifications({
          ...notifications,
          osNotifications: response.data.osNotifications,
        });

      }

    } catch (e) {

      console.log(e);
      NotificationManager.warning('API ERROR');

    }

  }

  const toggleCustomizer = () => {
    setDrawerStatus(!drawerStatus);
  };

  const closeCustomizer = () => {
    setDrawerStatus(false);
  };

  const handleChange = (event, value) => {
    setValueTabs(value);
  };

  const handleChangeIndex = (index) => {
    setValueTabs(index);
  };

  useEffect(() => {

    loadOsNotification();

  }, []);

  useEffect(() => {

    if (notifications.contractNotifications.length > 0 || notifications.osNotifications.length > 0 || notifications.ticketNotifications.length > 0) {
      setAlert(true);
    }

  }, [notifications]);

  return (
    <div className="d-none d-sm-block">
      <IconButton className="quick-menu icon-btn" onClick={toggleCustomizer}>
        {alert
          ? (
            <i className="fa fa-align-right icon-alert2" style={{fontSize: 19}} />
          )
          : <FaAlignRight style={{fontSize: 19}} />}
      </IconButton>
      <Drawer
        className="app-sidebar-content right-sidebar"
        anchor="right"
        open={drawerStatus}
        onClose={closeCustomizer}
      >
        <div className="aside-wrapper">
          <div className="aside-header">
            <IconButton onClick={closeCustomizer}>
              <ChevronLeft />
            </IconButton>
            <h4 className="m-0">Lista de Pendências</h4>
          </div>
          <div className="jr-tabs-content jr-task-list aside-content">
            <Tabs
              value={valueTab}
              onChange={handleChange}
              TabIndicatorProps={{style: {background: theme.palette.secondary.main, height: 4}}}
              textColor="primary"
              className="jr-tabs-up"
              style={{marginLeft: 60, marginRight: 60}}
              aria-label="icon label tabs pendencias"
              centered>
              <Tab
                className="mr-5"
                icon={(
                  <Badge
                    style={{top: 1}}
                    color="error"
                    badgeContent={notifications.osNotifications.length}
                    showZero={notifications.osNotifications.length > 0}>
                    <MdBadge style={{fontSize: 25}} />
                  </Badge>
                      )}
                label="OS" />
              <Tab
                className="mr-5"
                icon={(
                  <Badge
                    style={{top: 1}}
                    color="error"
                    badgeContent={notifications.contractNotifications.length}
                    showZero={notifications.contractNotifications.length > 0}>
                    <MdAssignmentInd style={{fontSize: 25}} />
                  </Badge>
                )}
                label="Contratos" />
              <Tab
                icon={(
                  <Badge
                    style={{top: 1}}
                    color="error"
                    badgeContent={notifications.ticketNotifications.length}
                    showZero={notifications.ticketNotifications.length > 0}>
                    <MdDescription style={{fontSize: 25}} />
                  </Badge>
                )}
                label="Tickets" />
            </Tabs>
            <div className="jr-tabs-content jr-task-list">
              <div className="m-0">
                <SwipeableViews
                  containerStyle={{
                    transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s'
                  }}
                  axis="x"
                  index={valueTab}
                  onChangeIndex={handleChangeIndex}>
                  <div className="col-12 p-0">
                    <PerfectScrollbar className={classes.scrollbarRoot}>
                      <CmtList
                        style={{paddingTop: 20}}
                        data={notifications.osNotifications}
                        renderRow={(data, index) => <NotificationCell key={index} dataNotification={data} />} />
                    </PerfectScrollbar>
                  </div>
                  <div className="col-12 p-0">
                    <PerfectScrollbar className={classes.scrollbarRoot}>
                      <CmtList
                        style={{paddingTop: 20}}
                        data={notifications.contractNotifications}
                        renderRow={(data, index) => <NotificationCell key={index} dataNotification={data} />} />
                    </PerfectScrollbar>
                  </div>
                  <div className="col-12 p-0">
                    <PerfectScrollbar className={classes.scrollbarRoot}>
                      <CmtList
                        style={{paddingTop: 20}}
                        data={notifications.ticketNotifications}
                        renderRow={(data, index) => <NotificationCell key={index} dataNotification={data} />} />
                    </PerfectScrollbar>
                  </div>
                </SwipeableViews>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <NotificationContainer />
    </div>
  );
}
