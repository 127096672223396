import React from 'react';

import {useSelector} from 'react-redux';

function AccessMenu(navigations) {
  const {profile} = useSelector(({user}) => user);

  const accessFiltered = navigations.map((item) => {
    if (item.group.includes(profile.group.name)) {
      return {
        ...item,
        children: item.children.map((itemChildren) => {
          if (itemChildren.children && itemChildren.group.includes(profile.group.name)) {
            return {
              ...itemChildren,
              children: itemChildren.children.filter((itemChildrenChildren) => {
                if (itemChildrenChildren.group.includes(profile.group.name)) {
                  return {
                    ...itemChildrenChildren
                  };
                }
              })
            };
          }
          if (!itemChildren.children && itemChildren.group.includes(profile.group.name)) {
            return {
              ...itemChildren,
            };
          }
        }).filter(Boolean)
      };
    }
  }).filter(Boolean);

  return accessFiltered;
}

/*
function AccessMenu(navigations) {
  const {profile} = useSelector(({user}) => user);

  function checkAccess(item) {

    const response = item.children.map((el) => {
      if (el.children) {
        return {
          ...el,
          children: el.children.map((elf) => {
            if (elf.group.includes(profile.group.name)) {
              return {
                ...elf
              };
            }
            return 0;

          })
        };
      }
      if (el.group.includes(profile.group.name)) {
        return {
          ...el
        };
      }
      return 0;

    });

    if (!response.includes(0)) {
      if (response.length === 1) {

        if (response[0].children) {
          if (response[0].children.includes(0)) {
            return null;
          }
          return response;

        }
        return response;

      }
      if (response.length > 1) {

        const resp = response.filter((element) => {
          if (element.children.length === 1) {
            return (element.children.includes(0) ? null : element);
          }
          return element;
        });

        return resp;

      }

    }
    return null;

  }

  const accessFiltered = navigations.map((item) => ({
    ...item,
    children: checkAccess(item),
  }));

  AccessMenu2(navigations);
  return accessFiltered.filter((item) => item.children !== null);

}

 */

export default AccessMenu;
