import axios from 'axios';

const apiGupShupCross = axios.create({
  baseURL: process.env.REACT_APP_URL_GUPSHUP,
  headers: {
    apikey: process.env.REACT_APP_API_KEY_CROSS_GUPSHUP
  }
});

const apiGupShupRedenilf = axios.create({
  baseURL: process.env.REACT_APP_URL_GUPSHUP,
  headers: {
    apikey: process.env.REACT_APP_API_KEY_REDENILF_GUPSHUP
  }
});

export {apiGupShupCross, apiGupShupRedenilf};
