import {
  all, call, put, fork, takeEvery
} from 'redux-saga/effects';
import api from '../services/api';
import {
  userUpdateAvatarFailure,
  userUpdateAvatarSuccess,
} from '../actions/User';
import { USER_UPDATE_AVATAR } from '../constants/ActionTypes';

export function* updateAvatar({ payload }) {
  try {
    const { id, email } = payload;

    const data = { avatar_id: id, email };

    const response = yield call(api.put, 'users', data);

    localStorage.removeItem('profile');
    localStorage.setItem('profile', JSON.stringify(response.data));
    yield put(userUpdateAvatarSuccess(response.data));
  } catch (err) {
    yield put(userUpdateAvatarFailure());
  }
}

export function* userUpdateAvatar() {
  yield takeEvery(USER_UPDATE_AVATAR, updateAvatar);
}

export default function* rootSaga() {
  yield all([fork(userUpdateAvatar)]);
}
