import React from 'react';
import {FaWallet} from 'react-icons/all';
import { makeStyles} from '@mui/styles';
import CustomScrollbars from '../../util/CustomScrollbars';
import CmtProgressBar from '../../@coremat/CmtProgressBar';

const useStyles = makeStyles((theme) => ({
  rootProgress: {
    '& .Cmt-label-container': {
      fontSize: 12,
    },
    '& .Cmt-text-container': {
      color: '#FFFFFF',
    },
  },
}));

export default function BotNotification({balance}) {

  const classes = useStyles();

  return (
    <CustomScrollbars className="messages-list scrollbar" style={{height: 190}}>
      <ul className="list-unstyled">
        {balance.map((notification, index) => (
          <li key={index} className="media">
            <FaWallet size="20px" />
            <div className="container">
              <div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: -10
              }}>
                <h4>{notification.name}</h4>
                <h6>{`$ ${new Intl.NumberFormat('en-US', {maximumFractionDigits: 2}).format(notification.amount)}`}</h6>
              </div>
              <div className={classes.rootProgress}>
                <CmtProgressBar
                  value={notification.percent}
                  renderValue={(value) => `${value}%`}
                  containedColor={notification.color}
                  thickness={6}
                  onlyContained
                  className="animated fadeInLeft"
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );
}
