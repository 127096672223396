import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import Special from './routes/Special'

const Schedule = ({match}) => {

  return(
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/general`} />
      <Route path={`${match.url}/general`} component={asyncComponent(() => import('./routes/General'))}/>
      <Route path={`${match.url}/special`} component={Special}/>
      <Route component={asyncComponent(() => import('../extraPages/routes/404'))}/>
    </Switch>
  </div>
);
}

export default Schedule;
