import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from '../../../util/asyncComponent';

const ProfileInfo = ({match}) => (
    <div className="app-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/profile-info`}/>
            <Route path={`${match.url}/profile-info`} component={asyncComponent(() => import('./routes/Profile'))}/>
            <Route component={asyncComponent(() => import('../extraPages/routes/404'))}/>
        </Switch>
    </div>
);

export default ProfileInfo;
