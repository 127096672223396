import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import asyncComponent from '../../util/asyncComponent';
import Schedule from "./Schedule";
import ProfileInfo from "./ProfileInfo";
import Configurations from "./Configurations"
import RH from "./RH";
import Projects from "./Projects";
import AfterSales from "./AfterSales";

const Routes = ({match}) =>
  <Switch>
    <Route path={`${match.url}/sample-page`}
           component={asyncComponent(() => import('./SamplePage'))}/>
    <Route path={`${match.url}/schedule`} component={Schedule}/>
    <Route path={`${match.url}/rh`} component={RH}/>
    <Route path={`${match.url}/projects`} component={Projects}/>
    <Route path={`${match.url}/after_sales`} component={AfterSales}/>
    <Route path={`${match.url}/dashboard`} component={asyncComponent(()=> import('./Dashboards'))}/>
    <Route path={`${match.url}/profile`} component={ProfileInfo}/>
    <Route path={`${match.url}/configuration`} component={Configurations}/>
    <Route component={asyncComponent(() => import("./extraPages/routes/404"))}/>
  </Switch>;


export default withRouter(Routes);

