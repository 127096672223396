import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import {
  Dialog, DialogTitle, DialogContent, createTheme, Box
} from '@mui/material';

import {makeStyles} from '@mui/styles';

import MUIDataTable from 'mui-datatables';
import {ThemeProvider} from '@mui/material/styles';

import { AssignmentInd, LocationCity, AccessTime } from '@mui/icons-material';
import clsx from 'clsx';
import {Row} from 'reactstrap';

const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
  table: {
    color: theme.palette.success.dark,
  },
  iconClock: {
    color: '#7282d8',
    marginBottom: 2
  },
  h6: {
    fontSize: 12,
    fontWeight: 700,
    letterSpacing: 0.5,
    marginTop: 2
  },
  boxText: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.5px',
  },
  title: {
    color: '#7282d8',
    paddingBottom: 0,
  },
}));

const myTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
    MUIDataTableHeadCell: {
      styleOverrides: {
        fixedHeader: {
          backgroundColor: '#3f51b5',
        },
        contentWrapper: {
          display: 'block'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '7px',
          textAlign: 'center',
          border: '1px solid #0000002e'
        }
      }
    },
  },
});

const TableShedule = ({ data, hours, rowsPage }) => {
  const [open, setOpen] = useState(false);
  const [searchClient, setSearchClient] = useState({});

  const classes = useStyles();

  const handleOpen = (cell) => {
    setOpen(true);

    const client = data.filter((c) => c.codcliente === cell);

    setSearchClient(...client);
  };

  const options = {
    selectableRows: 'none',
    filterType: 'dropdown',
    responsive: 'vertical',
    fixedHeader: true,
    fixedSelectColumn: false,
    tableBodyHeight: '750px',
    rowsPerPage: rowsPage || 11,
    rowsPerPageOptions: [rowsPage || 11, 22, 33, 44],
    onCellClick: (cellIndex, rowIndex) => {
      if (cellIndex) {
        handleOpen(cellIndex);
      }
    },
  };
  if (data) {

    const newData = [...new Set(data.map((x) => x.data))];

    const newTech = [...new Set(data.map((x) => x.tecnico))];

    const merge = (arr) => arr.reduce((obj, entry) => {
      for (const key in entry) {
        if (typeof obj[key] !== 'undefined' && entry[key] === '') continue;
        obj[key] = entry[key];
      }
      return obj;
    }, {});

    const carregarAgendaTecnico2 = (tecnico, horas) => {
      let array3 = [];
      data.forEach((d) => {
        if (d.tecnico === tecnico && d.hora === horas) {
          array3.push({
            tecnico,
            horas,
            [d.data]: d.codcliente,
          });
        } else if (d.tecnico === tecnico && d.hora !== horas) {
          array3.push({
            tecnico,
            horas,
            [d.data]: '',
          });
        }
      });

      array3 = array3.filter((v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i);

      // funcao que une os array desprezando os dias com undefined
      const newArray = merge(array3);
      // Clona os elementos do array de objetos e despreza os duplicados
      // array3 = Object.assign({}, ...array3);

      return newArray;
    };

    const dataColumn2 = [
      {
        name: 'tecnico',
        label: 'Técnico',
        options: {
          filter: true,
          setCellProps: (cellValue, rowIndex, ColumnIndex) => ({
            style: { background: '#3f51b5', fontSize: '12px'},
          }),
        },
      },
      {
        name: 'horas',
        label: 'Horas',
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValues) => (
            <>
              <div className="container">
                <AccessTime className={classes.iconClock} fontSize="small" />
                <h6 className={clsx(classes.h6)}>{value}</h6>
              </div>
            </>
          ),
          setCellProps: (cellValue, rowIndex, ColumnIndex) => ({
            style: { textAlign: 'center' },
          }),
        },
      },
      ...newData.map((item) => ({
        name: item,
        label: format(new Date(parseISO(item)), 'dd/MM EEEEEE', {
          locale: ptBR,
        }),
        options: {
          filter: false,
          setCellProps: (cellValue, rowIndex, ColumnIndex) => {
            const type = parseFloat(cellValue);
            switch (true) {
              case type === 0.1:
                return {
                  style: { background: '#380d0d96', cursor: 'pointer' },
                };
              case type > 1 && type < 2:
                return {
                  style: { background: '#bbbb0ab8', cursor: 'pointer' },
                };

              case type > 3 && type < 4:
                return {
                  style: { background: 'rgba(0,78,0,0.72)', cursor: 'pointer' },
                };

              case type > 5 && type < 6:
                return {
                  style: { background: 'rgba(107,53,19,0.72)', cursor: 'pointer' },
                };

              case type > 20 && type < 21:
                return {
                  style: { background: 'rgba(135,206,250,0.72)', cursor: 'pointer' },
                };
              case type > 21 && type < 22:
                return {
                  style: { background: 'rgba(134,229,134,0.72)', cursor: 'pointer' },
                };

              case type > 23 && type < 24:
                return {
                  style: { background: 'rgba(198,0,0,0.72)', cursor: 'pointer' },
                };

              case type > 62 && type < 63:
                return {
                  style: { background: 'rgba(156,97,139,0.72)', cursor: 'pointer' },
                };

              case type > 99 && type < 100:
                return {
                  style: { background: '#ec6a28', cursor: 'pointer' },
                };
              default:
                return null;
            }
          },
        },
      })),
    ];

    const dataRows2 = () => {
      let i = 0;
      const array = [];

      for (; i < newTech.length; i++) {
        hours.forEach((index, indice) => {
          array.push(carregarAgendaTecnico2(newTech[i], index));
        });
      }

      return array;
    };

    return (
      <>
        <ThemeProvider theme={myTheme}>
          <MUIDataTable title="Agenda" options={options} columns={dataColumn2} data={dataRows2()} />
        </ThemeProvider>

        <Dialog
          open={open}
          fullWidth
          maxWidth="md"
          aria-labelledby="max-width-dialog-title"
          onClose={() => setOpen(false)}
        >
          <DialogTitle className={classes.title} id="max-width-dialog-title">
            {searchClient.nome_razaosocial}
            <hr />
          </DialogTitle>
          <DialogContent>
            <Row>
              <div className="col-xl-6 col-lg-6 col-md-6 col-12 text-center text-white" style={{borderRight: '1px solid #4e5f6fcc'}}>
                <Box mb={3} display="inline-flex" alignItems="center">
                  <AssignmentInd />
                  <Box ml={3}>
                    <Box fontSize={14} className="text-lowercase text-capitalize">
                      OS:
                      {' '}
                      {searchClient.codos}
                    </Box>
                    <Box>{searchClient.tipo_servico}</Box>
                  </Box>
                </Box>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-12 text-center text-white">
                <Box mb={3} display="inline-flex" alignItems="center">
                  <LocationCity />
                  <Box ml={3}>
                    <Box fontSize={14} className="text-uppercase">
                      BAIRRO:
                      {searchClient.bairro}
                    </Box>
                    <Box className="text-uppercase">{searchClient.cidade_cli}</Box>
                  </Box>
                </Box>
              </div>
            </Row>
          </DialogContent>
        </Dialog>
      </>
    );
  }
  return null;
};

export default TableShedule;
