import React from 'react';
import {Box, Chip} from '@mui/material';
import { alpha } from '@mui/material/styles';
import {makeStyles, useTheme} from '@mui/styles';
import {FiCalendar} from 'react-icons/all';
import {parseISO} from 'date-fns';
import CmtAvatar from '../../../@coremat/CmtAvatar';

const useStyles = makeStyles((theme) => ({
  camCellItem: {
    paddingLeft: 20,
    paddingRight: 20,
    transition: 'all .2s',
    borderTop: `1px solid ${alpha(theme.palette.primary.contrastText, 0.04)}`,
    '&:last-child': {
      borderBottom: `1px solid ${alpha(theme.palette.primary.contrastText, 0.04)}`,
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
    },
  },
}));

const NotificationCell = ({ dataNotification }) => {
  const theme = useTheme();

  const {
    avatar, cliente, codigo, descricao, data, tipo, operador
  } = dataNotification;
  const statusColor = codigo > 0 ? '#8DCD03' : '#E00930';
  const classes = useStyles();
  return (
    <Box className={classes.camCellItem} display="flex" alignItems="center" justifyContent="space-between" px={6} py={2}>
      <Box display="flex" alignItems="center" flexDirection="row">
        <Box>
          <CmtAvatar
            alt={operador}
            size={36}
            variant="rounded"
            src={avatar || `https://ui-avatars.com/api/?background=5f9ea0&color=fff&name=${operador}`}
            />
        </Box>
        <Box ml={3}>
          <Box component="p">
            {cliente.length < 10 ? cliente : `${cliente.substr(0, 15)}...`}
          </Box>
          <Box display="flex" flexDirection="row">
            <FiCalendar />
            <Box ml={1} component="span" color="text.disabled" fontSize={12}>
              {new Intl.DateTimeFormat('pt-BR').format(new Date(parseISO(data)))}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box component="span" fontSize={12} color="text.disabled">
          {tipo}
        </Box>
      </Box>
      <Box color={statusColor}>
        <Box display="flex" alignItems="center" flexDirection="column-reverse">
          <Chip
            size="small"
            label={descricao}
            sx={{backgroundColor: theme.status.warning, color: '#fff', fontSize: 10}}
          />
          <Box component="h5">
            {codigo}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationCell;
