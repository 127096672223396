import React, {useEffect} from 'react';
import {createTheme} from '@mui/material';
import {ThemeProvider} from '@mui/styles';
import URLSearchParams from 'url-search-params';
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import {Redirect, Route, Switch} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {IntlProvider} from 'react-intl';
import 'assets/vendors/style';
import 'styles/global/_custom.scss';

import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK
} from 'constants/ThemeColors';
import RTL from 'util/RTL';
import indigoTheme from './themes/indigoTheme';
import cyanTheme from './themes/cyanTheme';
import orangeTheme from './themes/orangeTheme';
import amberTheme from './themes/amberTheme';
import pinkTheme from './themes/pinkTheme';
import blueTheme from './themes/blueTheme';
import purpleTheme from './themes/purpleTheme';
import greenTheme from './themes/greenTheme';
import AppLocale from '../lngProvider';
import {setDarkTheme, setThemeColor} from '../actions/Setting';
import AppLayout from './AppLayout';
import api from '../services/api';
import {setInitUrl} from '../actions/Auth';
import asyncComponent from "../util/asyncComponent";
import SignIn from "./SignIn";
import {ptBR} from "date-fns/locale";
import netpgTheme from "./themes/netpgTheme";

const RestrictedRoute = ({component: Component, authUser, ...rest}) => (
  <Route
    {...rest}
    render={(props) => (authUser
      ? <Component {...props} />
      : (
        <Redirect
          to={{
            pathname: '/signin',
            state: {from: props.location}
          }}
         />
      ))}
   />
);

const App = (props) => {
  const dispatch = useDispatch();
  const {
    themeColor, darkTheme, locale, isDirectionRTL
  } = useSelector(({settings}) => settings);
  const {authUser, initURL} = useSelector(({auth}) => auth);
  const isDarkTheme = darkTheme;
  const {match, location} = props;

  useEffect(() => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (initURL === '') {
      dispatch(setInitUrl(props.history.location.pathname));
    }
    const params = new URLSearchParams(props.location.search);
    if (params.has('theme-name')) {
      dispatch(setThemeColor(params.get('theme-name')));
    }
    if (params.has('dark-theme')) {
      dispatch(setDarkTheme());
    }
  }, [dispatch, initURL, props.history.location.pathname, props.location.search]);

  const getColorTheme = (themeColor, applyTheme) => {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createTheme(indigoTheme);
        break;
      }
      case CYAN: {
        applyTheme = createTheme(cyanTheme);
        break;
      }
      case AMBER: {
        applyTheme = createTheme(amberTheme);
        break;
      }
      case DEEP_ORANGE: {
        applyTheme = createTheme(orangeTheme);
        break;
      }
      case PINK: {
        applyTheme = createTheme(pinkTheme);
        break;
      }
      case BLUE: {
        applyTheme = createTheme(blueTheme);
        break;
      }
      case DEEP_PURPLE: {
        applyTheme = createTheme(purpleTheme);
        break;
      }
      case GREEN: {
        applyTheme = createTheme(greenTheme);
        break;
      }
      case DARK_INDIGO: {
        applyTheme = createTheme({...indigoTheme, direction: 'rtl'});
        break;
      }
      case DARK_CYAN: {
        applyTheme = createTheme(cyanTheme);
        break;
      }
      case DARK_AMBER: {
        applyTheme = createTheme(amberTheme);
        break;
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createTheme(orangeTheme);
        break;
      }
      case DARK_PINK: {
        applyTheme = createTheme(pinkTheme);
        break;
      }
      case DARK_BLUE: {
        applyTheme = createTheme(blueTheme);
        break;
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createTheme(purpleTheme);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createTheme(greenTheme);
        break;
      }
      default:
        createTheme(indigoTheme);
    }
    return applyTheme;
  };

  let applyTheme = createTheme(indigoTheme);
  if (isDarkTheme) {
    document.body.classList.add('dark-theme');
    applyTheme = createTheme(netpgTheme);
  } else {
    applyTheme = getColorTheme(themeColor, applyTheme);
  }
  if (location.pathname === '/') {
    return (<Redirect to="/app/dashboard" />);
  }

  if (authUser) {
    api.defaults.headers.Authorization = `Bearer ${authUser}`;

  }
  if (isDirectionRTL) {
    applyTheme.direction = 'rtl';
    document.body.classList.add('rtl');
  } else {
    document.body.classList.remove('rtl');
    applyTheme.direction = 'ltr';
  }

  const currentAppLocale = AppLocale[locale.locale];
  return (
    <ThemeProvider theme={applyTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
          <RTL>
            <div className="app-main">
              <Switch>
                <RestrictedRoute path={`${match.url}app`} component={AppLayout} authUser={authUser} />
                <Route path="/signin" component={SignIn} />
                <Route component={asyncComponent(() => import('../app/routes/extraPages/routes/404'))}/>
              </Switch>
            </div>
          </RTL>
        </IntlProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
