import React, {useEffect, useState} from 'react';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';

import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {TextField, Button, CircularProgress} from '@mui/material';
import IntlMessages from 'util/IntlMessages';
import {withStyles} from '@mui/styles';
import {hideMessage, userSignIn, showAuthLoader} from '../actions/Auth';

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#a9a9a9',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#08374c',
    },
  },
})(TextField);

const SignIn = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const {
    loader, alertMessage, showMessage, authUser
  } = useSelector(
    ({auth}) => auth
  );

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      props.history.push('/');
    }
  }, [showMessage, authUser, props.history, dispatch]);

  return (
    <div
      className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content bg-cyan darken-4">
        <div className="app-logo-content p-0 d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="NETPG">
            <img src={require('../assets/images/logo_netpg.svg')} style={{maxWidth: 270}} alt="NETPG" title="NETPG" />
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header">
            <h4 className="text-uppercase text-center text-light-grey font-weight-bold">Dashboard</h4>
            <div>
              <div className="app-login-form">
                <form>
                  <fieldset>
                    <CustomTextField
                      type="email"
                      label={<IntlMessages id="appModule.email" />}
                      fullWidth
                      onChange={(event) => setEmail(event.target.value)}
                      defaultValue={email}
                      margin="normal"
                      className="mt-1 my-sm-3"
                      variant="standard"
                    />
                    <CustomTextField
                      type="password"
                      label={<IntlMessages id="appModule.password" />}
                      fullWidth
                      onChange={(event) => setPassword(event.target.value)}
                      defaultValue={password}
                      margin="normal"
                      className="mt-1 my-sm-3"
                      variant="standard"
                    />

                    <div className="mt-3 float-right">
                      <Button
                        onClick={() => {
                          dispatch(showAuthLoader());
                          dispatch(userSignIn({email, password}));
                        }}
                        sx={{
                          backgroundColor: '#08374c',
                          '&:hover': {
                            backgroundColor: 'rgba(8,55,76,0.43)',
                          },
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Logar
                      </Button>

                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
          {
            loader && <div className="loader-view"><CircularProgress /></div>
          }
          {showMessage && NotificationManager.error(alertMessage)}
          <NotificationContainer />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
