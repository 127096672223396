import React, {useEffect, useState} from 'react';
import { Link, withRouter } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  AppBar, Toolbar, IconButton, CircularProgress, Drawer
} from '@mui/material';

import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants/ActionTypes';
import CardHeader from 'components/dashboard/Common/CardHeader/index';
import { toggleCollapsedNav } from 'actions/Setting';
import {useTheme} from '@mui/styles';
import {FaRobot} from 'react-icons/all';
import BotNotification from '../../../../components/BotNotification';
import {apiGupShupCross, apiGupShupRedenilf} from '../../../../services/apiGupShup';
import NotificationsControllerMK from '../../../NotificationAside/NotificationsControllerMK';

const Index = (props) => {

  const theme = useTheme();

  const [balance, setBalance] = useState([]);
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { drawerType, locale, navCollapsed } = useSelector(({settings}) => settings);
  const [appNotification, setAppNotification] = useState(false);

  const balanceFormatted = (balance) => balance.map((item) => {

    // REFERENCIA DE VALOR PARA REGARCA
    // CROSS 250 DOLARES
    // REDENILF 50 DOLARES

    let percent;

    if (item.name === 'Redenilf') {
      percent = 100 - Math.round((item.balance / 50) * 100);
    } else {
      percent = 100 - Math.round((item.balance / 250) * 100);
    }

    let color;

    if (percent >= 55) {

      if (percent <= 85) {

        color = theme.status.warning;
      } else {
        color = theme.status.danger;
        setAlert(true);
      }

    } else {
      color = theme.status.success;
    }

    return ({
      name: item.name,
      amount: item.balance,
      color,
      percent,
    });
  });

  const onAppNotificationSelect = () => {
    setAppNotification(!appNotification);
  };

  const onToggleCollapsedNav = (e) => {
    const val = !navCollapsed;
    dispatch(toggleCollapsedNav(val));
  };

  const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-block d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : 'd-none';

  useEffect(() => {

    async function loadWalletGupShup() {

      try {

        const response = await Promise.all([
          {name: 'Cross Conection', ...((await apiGupShupCross.get('/balance')).data)},
          {name: 'Redenilf', ...((await apiGupShupRedenilf.get('/balance')).data)}]);

        setBalance(balanceFormatted(response));
        setLoading(false);

      } catch (e) {
        console.log(e);
      }

    }
    loadWalletGupShup();

  }, []);

  return (
    <AppBar className="app-main-header">
      <Toolbar className="app-toolbar" disableGutters={false}>

        <IconButton
          className={`jr-menu-icon mr-3 ${drawerStyle}`}
          aria-label="Menu"
          onClick={onToggleCollapsedNav}>
          <span className="menu-icon" />
        </IconButton>

        <Link className="app-logo mr-2 d-none d-sm-block" to="/">
          <img src={require('assets/images/netpg_mini_2.png')} alt="Logo" title="NETPG" />
        </Link>

        <ul className="header-notifications list-inline ml-auto">
          {loading ? <CircularProgress size={25} />
            : (
              <>
                <li className="list-inline-item app-tour">
                  <Dropdown
                    className="quick-menu"
                    isOpen={appNotification}
                    toggle={onAppNotificationSelect}>

                    <DropdownToggle
                      className="d-inline-block"
                      tag="span"
                      data-toggle="dropdown">
                      <IconButton className="icon-btn">
                        {alert
                          ? (
                            <i
                              className="fa fa-robot icon-alert animated infinite wobble"
                              style={{fontSize: 18}} />
                          )
                          : <FaRobot />}
                      </IconButton>
                    </DropdownToggle>

                    <DropdownMenu right>
                      <CardHeader
                        styleName="align-items-center"
                        heading="MK Bot"
                        subHeading="Saldo"
                        hiddenMenuState />
                      <BotNotification balance={balance} />
                    </DropdownMenu>
                  </Dropdown>
                </li>
                <li className="list-inline-item">
                  <NotificationsControllerMK />
                </li>
              </>
            )}
        </ul>

        <div className="ellipse-shape" />
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(Index);
