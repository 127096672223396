import {indigo, teal} from '@mui/material/colors';
import {backgroundColor} from 'namor';

export default {
  palette: {
    mode: 'dark',
    primary: {
      light: teal[300],
      main: teal[600],
      dark: teal[900],
      contrastText: '#fff'
    },
    secondary: {
      light: indigo[300],
      main: indigo.A700,
      dark: indigo[900],
      contrastText: '#fff'
    },
    common: {
      black: '#000',
      white: '#fff',
      dark: '#020202',
    },
  },
  status: {
    danger: '#d94646',
    success: '#4CAF50',
    success_2: '#00C39EFF',
    warning: '#F39C12',
    disabled: '#00000042',
  },
  header: {
    primary: '#053245',
  },
  typography: {
    button: {
      fontWeight: 400,
      textAlign: 'capitalize'
    },
  },
  components: {
    MUIDataTableHeadCell: {
      styleOverrides: {
        fixedHeader: {
          backgroundColor: '#053245',
          color: '#FFFFFF'
        },
        contentWrapper: {
          justifyContent: 'center'
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
          textAlign: 'center',
        },
      }
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'none !important'
          }
        }
      }
    },
    MUIDataTablePagination: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
        }
      }
    },
    MUIDataTableFilter: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        }
      }
    },
  }
};
