import React, {useEffect, useState} from 'react';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import api from '../../../../../services/api';
import TableShedule from '../../../../../components/TableMK/TableShedule';
import ContainerHeader from '../../../../../components/ContainerHeader';
import AccessPage from "../../../../../security/AccessPage";
import {Route} from "react-router-dom";
import asyncComponent from "../../../../../util/asyncComponent";

export default ({match}) => {

  console.log(match)
  const access = AccessPage(match);

  if(!access){
    return (
      <Route component={asyncComponent(() => import('../../../extraPages/routes/404'))}/>
    )}


  const [loadSheduleDate, setSheduleData] = useState();

  const hours = [
    '08:30:00',
    '09:15:00',
    '10:00:00',
    '10:45:00',
    '11:30:00',
    '13:15:00',
    '14:00:00',
    '14:45:00',
    '15:30:00',
    '16:15:00',
    '17:00:00',
    '17:45:00',
    '18:30:00',
    '19:15:00',
    '20:00:00',
  ];

  useEffect(() => {
    async function loadShedule() {
      try {
        const shedule = await api('/shedule_special');

        if (shedule) {

          setSheduleData(shedule.data);
        }
      } catch (e) {
        NotificationManager.warning('API ERROR');
      }
    }
    loadShedule();
  }, []);

  return (
    <div className="animated slideInUpTiny animation-duration-3">
      <ContainerHeader match={match} title="Agenda" />
      <div className="row">
        <div className="col-12">
          <TableShedule data={loadSheduleDate} hours={hours} rowsPage={15} />
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};
