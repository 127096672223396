import {navigationMenus} from '../containers/SideBar/navigationMenus';
import AccessMenu from './AccessMenu';

export default function AccessPage(match) {

  const accessUser = AccessMenu(navigationMenus);
  const array = [...accessUser.map((item) => item.children)];

  let access = false;
  array.map((item) => item.forEach((el) => {
    if (el.children) {
      el.children.forEach((el2) => {
        if (el2.link === match.url) { access = true; }

      });
    } else if (el.link === match.url) { access = true; }
  }));

  return access;
}
