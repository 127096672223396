export const navigationMenus = [
  {
    name: 'sidebar.general',
    type: 'section',
    group: ['ADMINISTRADOR', 'OPERADOR', 'DIRETOR', 'SUPERVISOR'],
    children: [
      {
        name: 'sidebar.dashboard',
        icon: 'view-dashboard',
        type: 'collapse',
        group: ['ADMINISTRADOR', 'OPERADOR', 'DIRETOR', 'SUPERVISOR'],
        children: [
          {
            name: 'sidebar.dashboard.administration',
            type: 'item',
            group: ['ADMINISTRADOR', 'OPERADOR', 'DIRETOR', 'SUPERVISOR'],
            link: '/app/dashboard/administration'
          },
          {
            name: 'sidebar.dashboard.retention',
            type: 'item',
            group: ['ADMINISTRADOR', 'OPERADOR', 'DIRETOR', 'SUPERVISOR'],
            link: '/app/dashboard/retention'
          },
        ],
      },
      {
        name: 'sidebar.schedule',
        icon: 'calendar-note',
        sm: 'd-none d-sm-block',
        type: 'collapse',
        group: ['ADMINISTRADOR', 'OPERADOR', 'DIRETOR'],
        children: [
          {
            name: 'sidebar.schedule.general',
            type: 'item',
            group: ['ADMINISTRADOR', 'OPERADOR', 'DIRETOR'],
            link: '/app/schedule/general'
          },
          {
            name: 'sidebar.schedule.special',
            type: 'item',
            group: ['ADMINISTRADOR', 'OPERADOR', 'DIRETOR'],
            link: '/app/schedule/special'
          },
        ],
      },
    ]
  },
  {
    name: 'sidebar.projects',
    type: 'section',
    group: ['ADMINISTRADOR', 'OPERADOR', 'DIRETOR', 'SUPERVISOR'],
    children: [
      {
        name: 'sidebar.building',
        type: 'item',
        icon: 'city',
        group: ['ADMINISTRADOR', 'OPERADOR', 'DIRETOR', 'SUPERVISOR'],
        link: '/app/projects/building'
      },
    ]
  },
  {
    name: 'sidebar.rh',
    type: 'section',
    sm: 'd-none d-sm-block',
    group: ['ADMINISTRADOR', 'OPERADOR', 'RH', 'DIRETOR'],
    children: [
      {
        name: 'sidebar.commission',
        type: 'item',
        icon: 'folder-person',
        group: ['ADMINISTRADOR', 'OPERADOR', 'RH', 'DIRETOR'],
        link: '/app/rh/commission'
      },
    ]
  },
  {
    name: 'sidebar.after_sales',
    type: 'section',
    sm: 'd-none d-sm-block',
    group: ['ADMINISTRADOR'],
    children: [
      {
        name: 'sidebar.audit',
        type: 'item',
        icon: 'folder-person',
        group: ['ADMINISTRADOR'],
        link: '/app/after_sales/audit'
      },
    ]
  },
  {
    name: 'sidebar.config',
    type: 'section',
    sm: 'd-none d-sm-block',
    group: ['ADMINISTRADOR'],
    children: [
      {
        name: 'sidebar.config.configuration',
        icon: 'settings',
        type: 'collapse',
        group: ['ADMINISTRADOR'],
        children: [
          {
            name: 'sidebar.config.system',
            type: 'item',
            group: ['ADMINISTRADOR'],
            link: '/app/configuration'
          },
        ],
      },
    ]
  }
];
