import React, {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import {
  Button, DialogContent, DialogTitle, TextField, MenuItem
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Controller, useForm} from 'react-hook-form';
import {FaEdit} from 'react-icons/all';
import {NotificationManager} from 'react-notifications';
import api from '../../../../services/api';
import errorApi from '../../../../util/errorApi';

const ButtonEdit = styled(Button)({
  backgroundColor: '#1565c0'
});

export default ({
  dataUser, dataTable, setDataTable, open, dialog, dataGroup
}) => {
  const [defaultValues, setDefaultValues] = useState({});

  const {
    handleSubmit, control, reset
  } = useForm();

  useEffect(() => {

    setDefaultValues(dataUser);
    reset();

  }, [dataUser]);

  const onSubmit = async (data) => {

    Object.keys(data).forEach((key) => (data[key] === undefined || data[key] === '') && delete data[key]);

    try {

      const response = await api.put('/admin/users', data);

      const newUser = {
        id: response.data.id,
        name: response.data.name,
        email: response.data.email,
        group: response.data.group.name,
        created_at: new Intl.DateTimeFormat('pt-br').format(new Date(response.data.created_at)),
        updated_at: new Intl.DateTimeFormat('pt-br').format(new Date(response.data.updated_at)),
      };

      setDataTable([...dataTable.filter((item) => item.id !== newUser.id), newUser]);
      dialog(false);

      NotificationManager.info('Editado com Sucesso');

    } catch (e) {
      NotificationManager.warning(errorApi(e));
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      aria-labelledby="max-width-dialog-title"
      onClose={() => dialog(false)}
      >
      <DialogTitle id="max-width-dialog-title">
        Editar Usuário
        <hr />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-2">
              <Controller
                name="id"
                control={control}
                defaultValue={defaultValues.id}
                render={({
                  field: {
                    onChange, value
                  }
                }) => (
                  <TextField
                    label="Id"
                    fullWidth
                    margin="normal"
                    disabled
                    variant="outlined"
                    onChange={onChange}
                    value={value}
                    />
                )}
                />
            </div>
            <div className="col-10">
              <Controller
                name="name"
                control={control}
                defaultValue={defaultValues.name}
                render={({
                  field: {
                    onChange, value
                  }
                }) => (
                  <TextField
                    label="Nome"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={onChange}
                    value={value}
                    />
                )}
                />
            </div>
            <div className="col-12">
              <Controller
                name="email"
                control={control}
                defaultValue={defaultValues.email}
                render={({
                  field: {
                    onChange, value
                  }
                }) => (
                  <TextField
                    label="Email"
                    margin="normal"
                    disabled
                    type="email"
                    variant="outlined"
                    fullWidth
                    onChange={onChange}
                    value={value}
                    />
                )}
                />
            </div>
            <div className="col-12">
              <Controller
                name="group_id"
                control={control}
                defaultValue={defaultValues.group && defaultValues.group.id}
                render={({
                  field: {
                    onChange, value
                  }
                }) => (
                  <TextField
                    label="Grupo"
                    margin="normal"
                    select
                    variant="outlined"
                    fullWidth
                    onChange={onChange}
                    value={value}
                    >
                    {
                      dataGroup.map((group) => (
                        <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                      ))
                    }
                  </TextField>
                )}
                />
            </div>
            <div className="col-12">
              <Controller
                name="password"
                control={control}
                render={({
                  field: {
                    onChange, value
                  }
                }) => (
                  <TextField
                    label="Senha mínimo 6 caracteres"
                    margin="normal"
                    variant="outlined"
                    type="password"
                    fullWidth
                    onChange={onChange}
                    value={value}
                    />
                )}
                />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <ButtonEdit
                variant="contained"
                className="float-right my-4"
                type="submit"
                size="medium"
                endIcon={<FaEdit size={13} />}
                >
                Editar
              </ButtonEdit>
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>

  );
};
