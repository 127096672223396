import React from 'react';

import CustomScrollbars from 'util/CustomScrollbars';
import {navigationMenus} from './navigationMenus';
import Navigation from '../../components/Navigation';
import AccessMenu from '../../security/AccessMenu';

const SideBarContent = () => (
  <CustomScrollbars className=" scrollbar">
    <Navigation menuItems={AccessMenu(navigationMenus)} />
  </CustomScrollbars>
);

export default SideBarContent;
