import {
  USER_UPDATE_AVATAR,
  USER_UPDATE_AVATAR_SUCCESS,
  USER_UPDATE_AVATAR_FAILURE,
} from '../constants/ActionTypes';

export function userUpdateAvatar(data) {
  return {
    type: USER_UPDATE_AVATAR,
    payload: data,
  };
}

export function userUpdateAvatarSuccess(profile) {
  return {
    type: USER_UPDATE_AVATAR_SUCCESS,
    payload: { profile },
  };
}

export function userUpdateAvatarFailure() {
  return {
    type: USER_UPDATE_AVATAR_FAILURE,
  };
}
