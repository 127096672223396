import {
  SIGNOUT_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  USER_UPDATE_AVATAR_SUCCESS,
  USER_UPDATE_AVATAR_FAILURE,
} from 'constants/ActionTypes';

const storage = localStorage.getItem('profile');

const INIT_STATE = {
  profile: storage ? JSON.parse(storage) : null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        profile: action.payload.user,
      };
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        profile: null,
      };
    }
    case USER_UPDATE_AVATAR_SUCCESS: {
      return {
        ...state,
        profile: action.payload.profile,
      };
    }
    case USER_UPDATE_AVATAR_FAILURE: {
      return {
        ...state,
        profile: null,
      };
    }
    default:
      return state;
  }
};
