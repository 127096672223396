import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const Projects = ({match}) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/building`}/>
        <Route path={`${match.url}/building`} component={asyncComponent(() => import('./routes/Building'))}/>
        <Route component={asyncComponent(() => import('../extraPages/routes/404'))}/>
      </Switch>
    </div>
  );
}

export default Projects;
