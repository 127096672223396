import React, {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import {
  Button, DialogContent, DialogTitle, TextField
} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {FaTrash} from 'react-icons/all';
import {NotificationManager} from 'react-notifications';
import api from '../../../../services/api';
import errorApi from '../../../../util/errorApi';

export default ({
  dataUser, dataTable, setDataTable, open, dialog
}) => {
  const [defaultValues, setDefaultValues] = useState({});

  const {
    handleSubmit, control, reset
  } = useForm();

  useEffect(() => {

    setDefaultValues(dataUser);
    reset();

  }, [dataUser]);

  const onSubmit = async (data) => {

    const {id} = data;

    try {

      const response = await api.delete('/admin/users', {
        params: {
          id,
        }
      });

      setDataTable([...dataTable.filter((item) => item.id !== id)]);
      dialog(false);
      NotificationManager.info(response.data);

    } catch (e) {

      NotificationManager.warning(errorApi(e));
    }

  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      aria-labelledby="max-width-dialog-title"
      onClose={() => dialog(false)}
      >
      <DialogTitle id="max-width-dialog-title">
        Deletar Usuário
        <hr />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-2">
              <Controller
                name="id"
                control={control}
                defaultValue={defaultValues.id}
                render={({
                  field: {
                    onChange, value
                  }
                }) => (
                  <TextField
                    label="Id"
                    fullWidth
                    margin="normal"
                    disabled
                    variant="outlined"
                    onChange={onChange}
                    value={value}
                    />
                )}
                />
            </div>
            <div className="col-10">
              <Controller
                name="name"
                control={control}
                defaultValue={defaultValues.name}
                render={({
                  field: {
                    onChange, value
                  }
                }) => (
                  <TextField
                    label="Nome"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={onChange}
                    value={value}
                    />
                )}
                />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Button
                variant="contained"
                className="float-right my-4 bg-red darken-4 text-white"
                type="submit"
                size="medium"
                endIcon={<FaTrash size={13} />}
                >
                Deletar
              </Button>
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>

  );
};
