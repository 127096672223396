const errorApi = (error) => {
  if (error.response) {
    if (error.response.data.error) {
      return error.response.data.error;
    }
    if (error.response.status === 404) {
      return 'API NOT FOUND';
    }
  }
};

export default errorApi;
